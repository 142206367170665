import { Alert } from '../types';
import { AlertPanelFilter } from './types';
import { PagedRequestOptions, PagedResult } from 'store/common';
import { alertKeys } from '../queryKeys';
import { apiUrls } from 'api';
import { getNextPageParam } from 'utils/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';

export type PaginatedAlertRequest = PagedRequestOptions & AlertPanelFilter;
export type PagedAlertResponse = PagedResult<Alert>;

export const useInfiniteAlertListQuery = (alertPanelFilter: AlertPanelFilter) => {
  const queryInfo = useInfiniteQuery({
    getNextPageParam,
    initialPageParam: 1,
    queryFn: async ({ pageParam, signal }) => {
      const requestData: PaginatedAlertRequest = {
        ...alertPanelFilter,
        page: pageParam,
        size: 10
      };

      const response = await axios.post<PagedAlertResponse>(
        apiUrls.flow.searchAlerts(),
        requestData,
        { signal }
      );

      return response.data;
    },
    queryKey: alertKeys.list.byFilter(alertPanelFilter)
  });

  return queryInfo;
};
