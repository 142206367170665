import { AlertInProgressIcon, AlertOpenIcon, CatIconBaseProps, CheckIcon } from '@borda/cat-icons';
import { CatColorTypes, CatTypography } from '@borda/cat-ui';
import { ResponseStatuses } from '../types';
import { useCatTranslation } from 'utils/localization';
import React from 'react';
import clsx from 'clsx';

type Props = {
  chipSize?: 'small' | 'medium';
  status: ResponseStatuses;
};

function AlertStatusChip(props: Props) {
  const { chipSize = 'medium', status } = props;

  const { t: tAlertStatuses } = useCatTranslation('translation', {
    keyPrefix: 'alert.statuses'
  });

  const { Icon, backgroundColor, borderColor, iconColor, textColor } =
    getAlertStatusChipDefinitions(status);

  const isChipSmall = chipSize === 'small';

  return (
    <div
      className={clsx(
        'flex items-center gap-1 rounded-lg border px-1 py-0.5',
        backgroundColor,
        borderColor
      )}
    >
      <Icon alwaysHovered color={iconColor} fontSize="small" hoverable={false} />
      <CatTypography className={`${textColor} font-bold`} variant={isChipSmall ? 'body2' : 'body1'}>
        {tAlertStatuses(status)}
      </CatTypography>
    </div>
  );
}

export default AlertStatusChip;

type AlertStatusChipDefinition = {
  Icon: React.ComponentType<CatIconBaseProps>;
  backgroundColor: string;
  borderColor: string;
  iconColor: CatColorTypes;
  textColor: string;
};

const getAlertStatusChipDefinitions = (status: ResponseStatuses): AlertStatusChipDefinition => {
  if (status === 'open') {
    return {
      Icon: AlertOpenIcon,
      backgroundColor: 'bg-red/10',
      borderColor: 'border-red/10',
      iconColor: 'red',
      textColor: 'text-red'
    };
  }

  if (status === 'closed') {
    return {
      Icon: CheckIcon,
      backgroundColor: 'bg-green/10',
      borderColor: 'border-green/10',
      iconColor: 'green',
      textColor: 'text-green'
    };
  }

  return {
    Icon: AlertInProgressIcon,
    backgroundColor: 'bg-orange/10',
    borderColor: 'border-orange/10',
    iconColor: 'orange',
    textColor: 'text-orange'
  };
};
