import { AlertPanelFilter } from '../types';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { CatTypography } from '@borda/cat-ui';
import { LighthouseNoItemIcon, LighthouseOkIcon } from '@borda/cat-icons';
import { defaultAlertPanelFilter } from '../data';
import { useFindObjectChangesCount } from '@borda/cat-core';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

function EmptyContent() {
  const { watch } = useFormContext<AlertPanelFilter>();
  const selectedAlertPanelFilters = watch();

  const alertFilterChangesCount = useFindObjectChangesCount(
    selectedAlertPanelFilters,
    defaultAlertPanelFilter
  );

  const isFiltered = alertFilterChangesCount > 0;

  const { t } = useCatTranslation('translation', {
    keyPrefix: isFiltered ? 'common.empty_filter' : `alert_panel.empty_list`
  });

  const LighthouseIcon = isFiltered ? LighthouseNoItemIcon : LighthouseOkIcon;

  return (
    <div className="grid h-full items-center">
      <div className="grid justify-items-center gap-4">
        <LighthouseIcon style={{ fontSize: '64px' }} />
        <div className="grid gap-1">
          <CatTypography className={clsx(!isFiltered && 'text-green', 'text-center')} variant="h1">
            {t('title')}
          </CatTypography>
          <CatTypography className="text-center opacity-80" variant="body1">
            <CatTrans i18nKey="desc" t={t} />
          </CatTypography>
        </div>
      </div>
    </div>
  );
}

export default EmptyContent;
