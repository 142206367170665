import { CatColorTypes, CatTypography } from '@borda/cat-ui';
import {
  CatIconBaseProps,
  ExclamationHighIcon,
  ExclamationIcon,
  ExclamationMediumIcon
} from '@borda/cat-icons';
import { Priority } from 'views/Alert/types';
import { useCatTranslation } from 'utils/localization';
import React from 'react';
import clsx from 'clsx';

type Props = {
  chipSize?: 'small' | 'medium';
  priority: Priority;
  variant?: 'filled' | 'outlined';
};

function PriorityChip(props: Props) {
  const { chipSize = 'medium', priority, variant = 'filled' } = props;

  const { t: tAlertPriorities } = useCatTranslation('translation', {
    keyPrefix: 'alert.priorities'
  });

  const { Icon, backgroundColor, borderColor, iconColor, textColor } =
    getPriorityChipDefinitions(priority);

  const isFilled = variant === 'filled';
  const isChipSmall = chipSize === 'small';

  return (
    <div
      className={clsx(
        'flex items-center gap-1 rounded-lg border px-1 py-0.5',
        isFilled ? backgroundColor : 'bg-transparent',
        borderColor
      )}
    >
      <Icon
        color={isFilled ? 'lightGrey' : iconColor}
        contained={false}
        fontSize="small"
        hoverable={false}
      />
      <CatTypography
        className={clsx(isFilled ? 'text-white' : 'font-bold', textColor)}
        variant={isChipSmall ? 'body2' : 'body1'}
      >
        {tAlertPriorities(priority)}
      </CatTypography>
    </div>
  );
}

export default PriorityChip;

type PriorityChipDefinition = {
  Icon: React.ComponentType<CatIconBaseProps>;
  backgroundColor: string;
  borderColor: string;
  iconColor: CatColorTypes;
  textColor: string;
};

const getPriorityChipDefinitions = (priority: Priority): PriorityChipDefinition => {
  if (priority === 'low') {
    return {
      Icon: ExclamationIcon,
      backgroundColor: 'bg-blue/80',
      borderColor: 'border-blue/80',
      iconColor: 'blue',
      textColor: 'text-blue'
    };
  }
  if (priority === 'medium') {
    return {
      Icon: ExclamationMediumIcon,
      backgroundColor: 'bg-orange/80',
      borderColor: 'border-orange/80',
      iconColor: 'orange',
      textColor: 'text-orange'
    };
  }

  return {
    Icon: ExclamationHighIcon,
    backgroundColor: 'bg-red/80',
    borderColor: 'border-red/80',
    iconColor: 'red',
    textColor: 'text-red'
  };
};
