import { CatTypography } from '@borda/cat-ui';
import { PatientObject } from 'views/Alert/types';

type Props = Pick<PatientObject, 'firstName' | 'lastName' | 'protocolNo'>;

function Patient(props: Props) {
  const { firstName, lastName, protocolNo } = props;

  return (
    <div className="flex items-center space-x-1 truncate">
      <CatTypography className="font-bold" variant="body2">
        {firstName} {lastName}
      </CatTypography>
      <CatTypography variant="body2">| {protocolNo || '-'}</CatTypography>
    </div>
  );
}

export default Patient;
