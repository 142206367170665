import { CatTypography } from '@borda/cat-ui';
import { UserObject } from 'views/Alert/types';
import React from 'react';

type Props = Pick<UserObject, 'firstName' | 'lastName' | 'jobTitle'>;

function Patient(props: Props) {
  const { firstName, jobTitle, lastName } = props;

  return (
    <>
      <CatTypography className="font-bold" variant="body2">
        {firstName} {lastName}
      </CatTypography>
      <CatTypography variant="body2">| {jobTitle || '-'}</CatTypography>
    </>
  );
}

export default Patient;
