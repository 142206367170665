import { ActiveTag, AssetStatus, PatientType } from 'store/slices/common/types';
import { LocationV2 } from 'store/slices/locations/common/types';

export const priorities = ['low', 'medium', 'high'] as const;
export type Priority = (typeof priorities)[number];

export const responseStatuses = ['open', 'inProgress', 'closed'] as const;
export type ResponseStatuses = (typeof responseStatuses)[number];

export type ResponseSubstatuses = 'cantHandle' | 'acknowledge' | 'onIt' | 'resolve' | 'reject';

export const productTypes = ['asset', 'patient', 'staff'] as const;
export type ProductType = (typeof productTypes)[number];

export const objectTypes = ['asset', 'patient', 'user'] as const;
export type ObjectType = (typeof objectTypes)[number];

export type AlertDeviceType = 'asset' | 'patient' | 'staff' | 'infant'; // TODO: Ask backend for the device type

export interface AlertActiveTag extends ActiveTag {
  assignedObjectId: string;
  deviceType: AlertDeviceType;
}

interface BaseObject {
  activeTag: AlertActiveTag;
}

export interface UserObject extends BaseObject {
  firstName: string;
  jobTitle: string;
  lastName: string;
  summary: string;
}

// TODO: Ask backend for the PatientObject prop names
export interface PatientObject extends BaseObject {
  firstName: string;
  lastName: string;
  protocolNo: string;
  roomNumber: string;
  summary: string;
  type: PatientType;
  unitName: string;
}

// TODO: Ask backend for the AssetObject prop names
export interface AssetObject extends BaseObject {
  assetStatus: AssetStatus;
  brandName: string;
  categoryName: string;
  code: string;
  modelName: string;
  modelPhotoPath: string;
}

// TODO: Talk with team for the real time map left panel => card ui(almost same) and ds looks similar
export type ObjectDetails = {
  asset?: AssetObject;
  patient?: PatientObject;
  user?: UserObject;
};

export type AlertLogType =
  | 'alertStarted'
  | 'reTriggered'
  | 'locationUpdated'
  | 'repetitionTimedOut'
  | 'alertRepeated'
  | 'statusChanged'
  | 'alertClosed'
  | 'alertResponded';

type AlertLogGroup = 'activities'; // TODO: Ask backend for the AlertLogGroup types

export type AlertLog = {
  location: LocationV2; // TODO: Ask backend for the location type maybe it can be only locationfullpath array
  logGroup: AlertLogGroup;
  logTime: string;
  logType: AlertLogType;
  message: string;
};

// TODO: Backend also needs to return substatus for the (closed + rejected) scenario.
export type Alert = {
  alertStartedLog: AlertLog;
  branchId: string;
  createDate: string;
  header: string;
  id: string;
  lastActivityLog: AlertLog;
  objectActiveTag: AlertActiveTag;
  objectDetails: ObjectDetails;
  objectId: string;
  objectName: string;
  objectType: ObjectType;
  priority: Priority;
  status: ResponseStatuses;
};
