import { AssetObject } from 'views/Alert/types';
import { CatTypography } from '@borda/cat-ui';
import React from 'react';

type Props = Omit<AssetObject, 'assetStatus' | 'modelPhotoPath' | 'activeTag'>;

function Asset(props: Props) {
  const { brandName, categoryName, code, modelName } = props;

  return (
    <>
      <CatTypography variant="body2">{categoryName}</CatTypography>
      <CatTypography variant="body2">| {brandName}</CatTypography>
      <CatTypography variant="body2">| {modelName} |</CatTypography>
      <CatTypography className="font-semibold" variant="body2">
        {code}
      </CatTypography>
    </>
  );
}

export default Asset;
