import { Alert } from '../types';
import { AlertPanelFilter } from './types';
import { CatPopover } from '@borda/cat-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { PopupState, bindPopover } from 'material-ui-popup-state/hooks';
import { alertPanelFilterSchema } from './schema';
import { defaultAlertPanelFilter } from './data';
import { useInfiniteAlertListQuery } from './queries';
import { useInfiniteQueryItems } from 'utils/react-query';
import { withDialogWrapper } from 'packages/cat-wrappers/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

type Props = {
  open: boolean;
  popupState: PopupState;
};

function AlertPanel(props: Props) {
  const { open, popupState } = props;

  const handleClose = () => {
    popupState.close();
  };

  const formMethods = useForm<AlertPanelFilter>({
    defaultValues: defaultAlertPanelFilter,
    mode: 'all',
    resolver: yupResolver(alertPanelFilterSchema)
  });

  const { watch } = formMethods;
  const alertFilter = watch();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isPending: alertsPending,
    isSuccess
  } = useInfiniteAlertListQuery(alertFilter);

  const { items: alerts } = useInfiniteQueryItems<Alert>(data, isSuccess, 'id');

  return (
    <CatPopover
      {...bindPopover(popupState)}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      className="mt-2"
      marginThreshold={8}
      open={open}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      width="512px"
    >
      <div
        className="flex flex-col"
        style={{ height: 'calc(100vh - var(--page-space-notification-panel))' }}
      >
        <FormProvider {...formMethods}>
          <PanelHeader onClose={handleClose} />
          <PanelContent
            alerts={alerts}
            alertsLoading={alertsPending}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        </FormProvider>
      </div>
    </CatPopover>
  );
}

export default withDialogWrapper(AlertPanel);
