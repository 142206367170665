import { ReactNode } from 'react';
import clsx from 'clsx';

type Props = {
  children: ReactNode;
  className?: string;
};

function HeaderContainer(props: Props) {
  const { children, className } = props;
  return (
    <div className={clsx('border-darkGrey/10 flex gap-2 border-b p-4', className)}>{children}</div>
  );
}

export default HeaderContainer;
