import { CatTypography } from '@borda/cat-ui';
import { DeviceTimeIcon } from '@borda/cat-icons';
import { ReactElement } from 'react';
import clsx from 'clsx';

type TimeChipBaseProps = {
  chipSize?: 'small' | 'medium';
  time: ReactElement | string;
  title: string;
};

function TimeChipBase(props: TimeChipBaseProps) {
  const { chipSize = 'medium', time, title } = props;
  const isChipSmall = chipSize === 'small';

  return (
    <div
      className={clsx(
        'border-darkGrey/10 bg-darkGrey/10 flex items-center rounded-lg border pr-1',
        isChipSmall ? 'gap-0.5' : 'gap-1'
      )}
      title={title}
    >
      <DeviceTimeIcon fontSize={chipSize} />
      <CatTypography className="truncate" variant={isChipSmall ? 'body2' : 'body1'}>
        {time}
      </CatTypography>
    </div>
  );
}

export default TimeChipBase;
