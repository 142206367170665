import { Alert } from 'views/Alert/types';
import { LinearProgress } from '@borda/cat-mui';
import { isArrayNullOrEmpty } from '@borda/cat-core';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import AlertCard from '../AlertCard/AlertCard';
import EmptyContent from './EmptyContent';
import Scrollbars from 'react-custom-scrollbars-2';

type Props = {
  alerts: Alert[];
  alertsLoading: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
};

function PanelContent(props: Props) {
  const { alerts, alertsLoading, fetchNextPage, hasNextPage } = props;

  const isNoDataAvailable = isArrayNullOrEmpty(alerts) && !alertsLoading;

  const { inView, ref: infiniteScrollRef } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      // fetchNextPage cancels any running query
      // If query invalidation starts and fetchNextPage is called after that, invalidation fails
      // e.g. remounting the list with 3 items doesn't invalidate the query
      // Alternative: fetchNextPage?.({cancelRefetch:false}); without hasNextPage
      fetchNextPage?.();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  return (
    <div className="flex grow flex-col py-2.5">
      {isNoDataAvailable ? (
        <EmptyContent />
      ) : (
        <>
          {alertsLoading && <LinearProgress className="w-full" />}
          <Scrollbars>
            <div className="grid content-start justify-items-center px-4">
              <div className="grid w-full gap-2">
                {alerts.map((alert, index) => (
                  <AlertCard {...alert} index={index} key={alert.id} ref={infiniteScrollRef} />
                ))}
              </div>
            </div>
          </Scrollbars>
        </>
      )}
    </div>
  );
}

export default PanelContent;
