import { AlertPanelFilter } from './types';

export const defaultAlertPanelFilter: AlertPanelFilter = {
  branches: [],
  extraFilters: [],
  priorities: [],
  products: [],
  sort: null,
  statuses: []
};

export const clearableFieldsKeys: Array<keyof AlertPanelFilter> = [
  'branches',
  'extraFilters',
  'priorities',
  'products',
  'sort'
];
