import { Camera } from './types';
import { DynamicListDefinition } from '@borda/cat-core';

export const initialColumnDynamicData: DynamicListDefinition<Camera> = {
  columns: [
    {
      column: 'host',
      visible: true,
      width: 100
    },
    {
      column: 'name',
      visible: true,
      width: 150
    },
    {
      column: 'description',
      visible: true,
      width: 150
    },
    {
      column: 'locationFullPath',
      visible: true,
      width: 200
    },
    {
      column: 'updatedDate',
      visible: true,
      width: 150
    },
    {
      column: 'lastRecordTime',
      visible: true,
      width: 150
    },
    {
      column: 'isConnected',
      visible: true,
      width: 150
    }
  ],
  sorts: [{ column: 'updatedDate', direction: 'desc' }]
};
