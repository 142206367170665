import { AlertIcon } from '@borda/cat-icons';
import { CatIconButton } from '@borda/cat-ui';
import { MutableRefObject } from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import AlertPanel from '../AlertPanel';

interface Props {
  popoverAnchorRef: MutableRefObject<HTMLDivElement>;
}

function AlertPanelButton(props: Props) {
  const { popoverAnchorRef } = props;

  const popupState = usePopupState({ popupId: 'alert-panel', variant: 'popover' });

  const handleAlertButtonClick = () => {
    popupState.open(popoverAnchorRef.current);
  };

  return (
    <>
      <CatIconButton onClick={handleAlertButtonClick}>
        <AlertIcon alwaysHovered={popupState.isOpen} className="h-10 w-10" color="red" />
      </CatIconButton>
      <AlertPanel open={popupState.isOpen} popupState={popupState} />
    </>
  );
}

export default AlertPanelButton;
