import { AlertLog } from 'views/Alert/types';
import HistoryItem from './HistoryItem';

type Props = {
  alertStartedLog: AlertLog;
  lastActivityLog: AlertLog;
};

function AlertCardHistory(props: Props) {
  const { alertStartedLog, lastActivityLog } = props;

  return (
    <div className="relative flex flex-col gap-2">
      <div className="bg-darkGrey/80 absolute bottom-[35.5px] left-[9.3px] top-[20.5px] w-px" />
      <HistoryItem {...alertStartedLog} />
      <HistoryItem {...lastActivityLog} />
    </div>
  );
}

export default AlertCardHistory;
