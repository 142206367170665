import { extraFilters } from './types';
import { priorities, productTypes, responseStatuses } from '../types';
import yup from 'utils/yup/yupExtended';

export const alertPanelFilterSchema = yup.object().shape({
  branches: yup.array().of(yup.string()),
  extraFilters: yup
    .array()
    .of(yup.string().oneOf([...extraFilters]))
    .nullable(),
  priorities: yup
    .array()
    .of(yup.string().oneOf([...priorities]))
    .nullable(),
  products: yup
    .array()
    .of(yup.string().oneOf([...productTypes]))
    .nullable(),
  statuses: yup
    .array()
    .of(yup.string().oneOf([...responseStatuses]))
    .nullable()
});
