import { ActiveLocation } from 'store/slices/common/types';
import { CatTypography } from '@borda/cat-ui';
import { ObjectDetails } from 'views/Alert/types';
import { useCatTranslation } from 'utils/localization';
import { useGetAlertCardLastSeenLocation } from 'views/Alert/AlertPanel/AlertCard/helpers';
import AlertCardLocation from '../../Common/AlertCardLocation';

type Props = {
  objectDetails: ObjectDetails;
};

function LastSeenLocation(props: Props) {
  const { objectDetails } = props;
  const { asset, patient, user } = objectDetails ?? {};

  const { t } = useCatTranslation('translation', {
    keyPrefix: 'alert_panel.alert_card'
  });

  const getObjectActiveLocation = (): ActiveLocation => {
    if (asset) {
      return asset.activeTag?.activeLocation;
    }

    if (user) {
      return user.activeTag?.activeLocation;
    }

    if (patient) {
      return patient.activeTag?.activeLocation;
    }

    return null;
  };

  const { lastSeenTime, locationFullPath } =
    useGetAlertCardLastSeenLocation(getObjectActiveLocation());

  return (
    <div className="flex w-full items-center gap-1">
      <CatTypography className="whitespace-nowrap opacity-80" variant="body2">
        {t('last_seen_location')}
      </CatTypography>
      <AlertCardLocation
        lastSeenTime={lastSeenTime}
        locationFullPath={locationFullPath}
        locationVariant="activeLocation"
      />
    </div>
  );
}

export default LastSeenLocation;
