import { ActiveLocation } from 'store/slices/common/types';
import { CatColorTypes, useLocalizationHelpers } from '@borda/cat-ui';
import { ResponseStatuses } from 'views/Alert/types';
import { isArrayNullOrEmpty } from '@borda/cat-core';
import { useCatTranslation } from 'utils/localization';

export const getHeaderIconColor = (status: ResponseStatuses): CatColorTypes => {
  if (status === 'closed') {
    return 'green';
  }

  if (status === 'open') {
    return 'red';
  }

  return 'orange';
};

export const getAlertCardBorderHoverColor = (status: ResponseStatuses): string => {
  if (status === 'closed') {
    return 'hover:border-green';
  }

  if (status === 'open') {
    return 'hover:border-red';
  }

  return 'hover:border-orange';
};

export type AlertCardLocation = {
  lastSeenTime: string;
  locationFullPath: string;
};

export const useGetAlertCardLastSeenLocation = (
  activeLocation: ActiveLocation
): AlertCardLocation => {
  const { formatDate, formatTime } = useLocalizationHelpers();

  const { t: tUnknownLocation } = useCatTranslation('translation', {
    keyPrefix: 'common.active_location_card.unknown_location'
  });

  if (activeLocation === null) {
    return {
      lastSeenTime: '-',
      locationFullPath: '-'
    };
  }

  const isUnknownLocation = activeLocation && isArrayNullOrEmpty(activeLocation.locationFullPath);

  if (isUnknownLocation) {
    return {
      lastSeenTime: '-',
      locationFullPath: tUnknownLocation('title')
    };
  }

  return {
    lastSeenTime: `${formatDate(activeLocation.lastSeen)} ${formatTime(activeLocation.lastSeen)}`,
    locationFullPath: [...activeLocation.locationFullPath].reverse().join(' / ')
  };
};
