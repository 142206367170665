import { AlertResponseIcon } from '@borda/cat-icons';
import { CatIconButton, CatTypography } from '@borda/cat-ui';
import { useCatTranslation } from 'utils/localization';

function AlertLastResponse() {
  const { t: tAlertCard } = useCatTranslation('translation', {
    keyPrefix: 'alert_panel.alert_card'
  });

  const handleResponseButtonClick = () => {
    // TODO: After Clicked => Open Dialog
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-0.5">
        <CatTypography className="opacity-80" variant="body2">
          {tAlertCard('last_response')}
        </CatTypography>
        <CatTypography className="font-bold" variant="body2">
          {tAlertCard('no_response')}
        </CatTypography>
      </div>
      <CatIconButton onClick={handleResponseButtonClick}>
        <AlertResponseIcon color="blue" />
      </CatIconButton>
    </div>
  );
}

export default AlertLastResponse;
