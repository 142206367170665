import { ObjectDetails } from 'views/Alert/types';
import AlertObjectName from './AlertObjectName';
import LastSeenLocation from './LastSeenLocation';
import React from 'react';

type Props = {
  objectDetails: ObjectDetails;
};

function AlertObjectType(props: Props) {
  const { objectDetails } = props;

  return (
    <div className="flex flex-col gap-2">
      <AlertObjectName objectDetails={objectDetails} />
      <LastSeenLocation objectDetails={objectDetails} />
    </div>
  );
}

export default AlertObjectType;
