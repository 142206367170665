import { TimeUnitTextForm } from './types';
import { differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';
import { useCatTranslation } from 'utils/localization';
import TimeChipBase from './TimeChipBase';

type Props = {
  chipSize?: 'small' | 'medium';
  date: string;
  timeUnitForm?: TimeUnitTextForm;
};

function TimeChipByDate(props: Props) {
  const { chipSize = 'medium', date, timeUnitForm = 'long' } = props;

  const { t: tTime } = useCatTranslation('translation', {
    keyPrefix: `common.time.time_by_unit.${timeUnitForm}`
  });

  const getTimeText = (): string => {
    const now = new Date();
    const past = new Date(date);

    const hours = differenceInHours(now, past);
    const minutes = differenceInMinutes(now, past);
    const seconds = differenceInSeconds(now, past);

    if (seconds < 60) {
      return tTime('seconds', { value: seconds });
    }

    if (minutes < 60) {
      return `${tTime('minutes', { value: minutes })} ${tTime('seconds', { value: seconds % 60 })}`;
    }

    return `${tTime('hours', { value: hours })} ${tTime('minutes', { value: minutes % 60 })}`;
  };

  return <TimeChipBase chipSize={chipSize} time={getTimeText()} title={getTimeText()} />;
}

export default TimeChipByDate;
